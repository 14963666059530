import React from "react";
import PropTypes from "prop-types";

import LogoMedia from "../../assets/mediaUpdates/logo-media.png";

const YouTubeVideo = ({ isYouTubeVideo = true, media, previewImage, className }) => {
  if(!isYouTubeVideo) {
    return <video controls src={media} className={`static-video mt-4 ${className}`}></video>
  }

  if (previewImage) {
    return <img className="w-full h-full object-contain" src={previewImage?.file?.url} alt="media image" />;
  } else if (media) {
    return (
      <iframe
        className={className}
        src={media}
        frameBorder="0"
        allow="encrypted-media"
        allowFullScreen
        title="video"
      />
    );
  } else {
    return <img className="w-full !h-full object-contain" src={LogoMedia} alt="media image" />;
  }
};

YouTubeVideo.propTypes = {
  media: PropTypes.string,
  previewImage: PropTypes.object,
  className: PropTypes.string,
};
YouTubeVideo.defaultProps = {
  media: "",
  media: {},
  className: "",
};
export default YouTubeVideo;
