import React from "react";
import { Link } from "gatsby";

import announcementIcon from "../../../../images/announcement-banner-icon.png";

import "./index.scss";

const AnnouncementBanner = ({ title, buttonLabel, buttonLink }) => {
  return (
    <Link className="announcement-banner-wrapper" to={buttonLink}>
      <div className="announcement-banner">
        <img className="announcement-banner-icon" src={announcementIcon} alt="Announcement Banner Icon" />
        <div>
          <h5 className="text-white uppercase font-medium mb-2">{title}</h5>
          <div className="uppercase text-white font-xs sm:font-medium no-light">{buttonLabel}</div>
        </div>
      </div>
    </Link>
  );
};

export default AnnouncementBanner;
